@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  font-family: "Inter", serif !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

p {
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
}

.slick-arrow:before {
  color: #47DFDF !important;
  font-size: 50px !important;
}


.slick-prev {
  top: -14% !important;
  left: 92% !important;
}

.slick-next {
  right: 3% !important;
  top: -14% !important;
}

@media (max-width: 1439px) {
  .slick-prev {
    left: 88% !important;
  }

  .slick-next {
    right: 3% !important;
  }
}

@media (max-width: 1023px) {
  .slick-prev {
    top: 5% !important;
    left: 20% !important;
  }

  .slick-next {
    right: 25% !important;
    top: 5% !important;
  }
}

.text-gradient {
  background: linear-gradient(to right, #47DFDF, #40CBCB, #7D7D7D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;

}


input {
  color: #7D7D7D !important;
}

input:focus {
  outline: none !important;
}

.custom-button {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: #000;
  color: #ffffff;
  border-color: #000 !important;
  border-radius: 50px !important;
}

.custom-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  border-radius: 50px;
  background-color: #fff;
  border: #fff !important;
  z-index: -1;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 500ms ease-in-out;
}

.custom-button:hover::before {
  transform: translate(-50%, -50%) scale(1.5);
  border-radius: 50px;
}

.custom-button:hover {
  color: #000;
  border-color: white !important;
}

.flex-col-reverse {
  flex-flow: column-reverse;
}



.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  scrollbar-width: none;
}

.image-container {
  position: relative;
  display: inline-block;

}

.image-container::before {
  content: '';
  position: absolute;
  top: 40px;
  left: 60px;
  right: 0;
  bottom: 0;
  background-color: #75e4e48c;
  filter: blur(80px);
  z-index: 0;
  height: 260px;
  width: 250px;
}

.image-container img {
  position: relative;
  z-index: 1;

}


.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.ul {
  list-style-type: disc;
  margin-left: 20px;
}
.ol {
  list-style-type:decimal;
  margin-left: 20px;
}

.ul-s {
  margin-left: 30px !important;
}

.bg-shad{
  background: url('./assets/vector/BG shade.png') no-repeat top center;
}